class ScrollClassToggler {
  constructor(elementSelector, offset, className) {
    this.element = document.querySelector(elementSelector);
    this.offset = offset;
    this.className = className;
    this.init();
  }

  // Método para inicializar el evento de desplazamiento
  init() {
    window.addEventListener('scroll', () => this.handleScroll());
    this.handleScroll(); // Verificar inmediatamente al cargar la página
  }

  // Método para manejar el evento de desplazamiento
  handleScroll() {
    if (this.isScrolledPastOffset()) {
      this.addClass();
    } else {
      this.removeClass();
    }
  }

  // Método para verificar si se ha superado el desplazamiento
  isScrolledPastOffset() {
    return window.scrollY > this.offset;
  }

  // Método para agregar la clase
  addClass() {
    this.element.classList.add(this.className);
  }

  // Método para eliminar la clase
  removeClass() {
    this.element.classList.remove(this.className);
  }
}

class ElementAnimator {
  constructor(elementSelector, animationClasses, threshold = 0.5, delay = 0) {
    this.element = document.querySelector(elementSelector);
    this.animationClasses = animationClasses;
    this.threshold = threshold;
    this.delay = delay;
    if (this.element) {
      this.hideElement();
      this.initObserver();
    }
  }

  hideElement() {
    this.element.style.opacity = '0';
  }

  initObserver() {
    const observer = new IntersectionObserver(this.handleIntersect.bind(this), {
      threshold: this.threshold
    });
    observer.observe(this.element);
  }

  handleIntersect(entries, observer) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        if (this.delay > 0) {
          setTimeout(() => this.animateElement(entry.target, observer), this.delay);
        } else {
          this.animateElement(entry.target, observer);
        }
      } else if (entry.boundingClientRect.top < 0) {
        observer.unobserve(entry.target);
      }
    });
  }

  animateElement(target, observer) {
    target.style.opacity = '1';
    target.classList.add(...this.animationClasses);
    observer.unobserve(target);
  }
}

class Menu {
  constructor(menuSelector, overlaySelector, openButtonSelector, closeButtonSelector, subMenuButtonSelector) {
    this.menu = document.querySelector(menuSelector);
    this.menuOverlay = document.querySelector(overlaySelector);
    this.openMenuButton = document.querySelector(openButtonSelector);
    this.closeMenuButton = document.querySelector(closeButtonSelector);
    this.openSubMenuButtons = document.querySelectorAll(subMenuButtonSelector);

    this.init();
  }

  init() {
    this.openMenuButton.addEventListener('click', () => this.toggleMenu(true));
    this.closeMenuButton.addEventListener('click', () => this.toggleMenu(false));
    this.menuOverlay.addEventListener('click', () => this.toggleMenu(false));
    this.openSubMenuButtons.forEach(button =>
      button.addEventListener('click', () => this.toggleSubMenu(button))
    );
  }

  toggleMenu(isActive) {
    this.menu.classList.toggle('active', isActive);
    this.menuOverlay.classList.toggle('active', isActive);
  }

  toggleSubMenu(button) {
    const listItem = button.closest('.hasChild');
    const subMenu = listItem.querySelector('ul');
    if (subMenu) {
      subMenu.classList.toggle('active');
    }
  }
}



// Crear una instancia de ScrollClassToggler para aplicar la lógica
new ScrollClassToggler('.site-header', 50, 'active');

// Site Animations 
new ElementAnimator('.about-us-home', ['animate__animated', 'animate__slideInUp'], 0.3);
new ElementAnimator('.our-clients-home', ['animate__animated', 'animate__slideInUp'], 0.3);
new ElementAnimator('.our-partners-home', ['animate__animated', 'animate__slideInUp'], 0.3);

// Inicialización del menú
const mobileMenu = new Menu('#mobile-menu', '.menu-overlay', '#mobile-menu-open', '#close-menu', '.open-submenu');
